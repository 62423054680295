import React from 'react';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import MailToBtn from './components/MailToBtn';
import bg_img from './media/tcc_pic_min.jpg';
import logo_src from './media/tcc_logo.jpg';

function App() {
  const banner_title = 'טי.סי.סי קול סנטר';
  const banner_text = 'מוקד שיווק ושירות טלפוני מחויבים למצוינות';
  const list_items = [
    'הגדרה ועמידה ביעדים איכותיים וכמותיים',
    'כ-200 עמדות מאוישות וממוחשבות',
    'זמינות אפשרית של 24x7',
    'שיחות מוקלטות ומאומתות',
    'שותפות צמודה עם לקוחות לקידום השגת יעדים עסקיים',
    'מתן פתרונות ברמה גבוהה ומקצועית',
    'פיתוח מתמיד של מיומנויות של נציגי שירות',
    'פיתוח ידע וחדשנות',
    'דגש מיוחד על הצוות המקצועי שמועסק בחברה'
  ];
  
  const bannerStyling = {
    backgroundImage: `url(${bg_img})`
  }
  
  return (
    <>
      <div className='heading'>
        <MailToBtn>צור קשר</MailToBtn>
        <img src={logo_src} alt="TCC call center ltd" />
      </div>
      <div className='banner' style={bannerStyling}>
        <div className='banner_centered'>
          <h2 className='banner_title'>{banner_title}</h2>
          <p className='banner_text'>מוקד שיווק ושירות טלפוני</p>
          <p className='banner_text'>מחויבים למצוינות</p>
        </div>
      </div>
      <div className='content'>
        <p className='content_title'>פתרונות עסקיים מותאמים לצרכים שלכם:</p>
        <ul className='content_list'>
          {list_items.map(item => {
            return <li key={item}>{item}</li>
          })}
        </ul>
      </div>
      <AboutUs />
      <Footer />
    </>
  );
}

export default App;
