const AboutUs = () => {
    const contentText = `טי.סי.סי קול סנטר מפעילה מוקד למתן פתרונות עסקיים לחברות וארגונים שונים, בהם נמנים חברות תקשורת, חברות עסקיות, עמותות ומועצות מקומיות. החברה מספקת ללקוחות שירותים על פי הצורך ומתאימה מענה לפי דרישות הלקוח, בין היתר שיווק ומיתוג (בשיחות יוצאות), שירות לקוחות (בשיחות נכנסות) ועוד.
    בנוסף, החברה מספק שירותי ענן ותקשורת ללקוחות, כולל שרות תמיכה מלא.
    החברה מספקת לעובדים במוקד את כל הכלים והתנאים הנדרשים, ומספקת סביבה תומכת וחווית יצירה תוך הקפדה על הגדרת יעדים איכותיים וכמותיים ועמידה בהם.
    במוקדים שמפעילה החברה קיימים כ- 200 עמדות ממוחשבות ומאוישות.`;

    return <div className="about_us">
        <h2>אודות</h2>
        <div className="about_text">{contentText}</div>
    </div>
};

export default AboutUs;