const Footer = () => {
    const info_list = [
        `משרדי החברה נמצאים בת.ד 4288, מיקוד 2012800 מג'אר`,
        'תחום הארגון: שירותי קול סנטר ושירותים נוספים',
        'כתובת דואר אלקטרוני: admin@TCC-Call-Center.co.il',
        'מספר טלפון: 04-6724822'
    ]

    const info_list_eng = [
        'Company offices are located in P.O 4288, ZIP Code 2012800, Mughar, Israel',
        'Organization specialty: Call center and additional services',
        'Email: admin@TCC-Call-Center.co.il',
        'Phone number: +97246724822'
    ]

    return <div className="footer">
        <ul>
            {info_list.map(item => {
                return <li key={item}>{item}</li>
            })}
        </ul>
        <ul className="eng_info_footer">
            {info_list_eng.map(item => {
                return <li key={item}>{item}</li>
            })}
        </ul>
    </div>
};

export default Footer;