const MailToBtn = (props) => {
    return (
        <a className="contact_us_link" target='_blank' rel="noreferrer" href={`mailto:admin@TCC-Call-Center.co.il?subject=צור קשר&body=מעוניין בשירותי אופיס 365`}>
            <button className="button-35">
                {props.children}
            </button>
        </a>
    )
};

export default MailToBtn;